<template>
  <div>
    <div class="header">
      <div @click="$router.back(0)" class="titlename">{{ titlename }}></div>
      <div class="current">赛事详情</div>
    </div>
    <div class="head">
      <p class="head-title">{{ eventdate.matchName }}</p>
      <div style="display: flex">
        <img class="head-img" :src="image" alt="" />
        <div class="head-tetx">
          <div>
            <p class="head-name">
              ꔷ 报名时间：{{ basicdate.registerDateStart }}
            </p>
            <p class="head-name" style="margin-left: 95px">至</p>
            <p class="head-name" style="margin-left: 95px">
              {{ basicdate.registerDateEnd }}
            </p>
          </div>
          <div class="head-name" style="display: flex">
            <div class="name">ꔷ 比赛时间：</div>
            <div>
              <div v-for="(item, index) in eventdate.matchDate" :key="index">
                <div v-if="item.startDate == item.endDate">
                  {{ item.startDate }}
                </div>
                <div v-else>{{ item.startDate }} 至 {{ item.endDate }}</div>
              </div>
            </div>
          </div>
          <p class="head-name">
            ꔷ 赛事地点：{{ eventdate.matchAddressStadium }}
          </p>
          <!-- <p class="head-name">联系方式：{{title4}}</p> -->
        </div>
      </div>
    </div>
    <div class="nav-box">
      <div
        @click="gettrigger(1)"
        :class="triggerindex == 1 ? 'nav active' : 'nav'"
      >
        竞赛规程
      </div>
      <div
        @click="gettrigger(2)"
        :class="triggerindex == 2 ? 'nav active' : 'nav'"
      >
        组别项目
      </div>
      <div
        @click="gettrigger(3)"
        :class="triggerindex == 3 ? 'nav active' : 'nav'"
      >
        赛事报名
      </div>
    </div>
    <div class="line"></div>

    <div class="display-box">
      <div v-html="date" v-if="triggerindex == 1"></div>
      <div v-if="triggerindex == 2">
        <div class="searchfor">
          <el-select
            placeholder="请选择"
            class="project"
            v-model="groupName"
            clearable
          >
            <el-option
              v-for="item in options1"
              :key="item.groupName"
              :label="item.groupName"
              :value="item.groupName"
            >
            </el-option>
            <template slot="prefix">
              <div style="display: flex">
                <span class="searchforlogo">
                  <img src="../assets/image/project2.png" alt="" srcset="" />
                </span>
                <span class="title">组别：</span>
              </div>
            </template>
          </el-select>
          <div class="but" @click="gradeGrouping">
            <div class="img">
              <img src="../assets/image/searchfor.png" alt="" />
            </div>
            <div class="text">搜索</div>
          </div>
        </div>

        <div class="table">
          <el-table
            :data="tableData"
            border
            style="width: 100%"
            :header-cell-style="{ background: '#0A6DF3' }"
          >
            <el-table-column
              type="index"
              label="序号"
              align="center"
              width="78"
            >
            </el-table-column>
            <el-table-column prop="groupName" label="组别" align="center">
            </el-table-column>
            <el-table-column prop="projectName" label="项目" align="center">
            </el-table-column>
            <el-table-column prop="sex" label="性别" align="center">
            </el-table-column>
            <el-table-column prop="birthDate" label="出生日期" align="center">
            </el-table-column>
          </el-table>
        </div>
      </div>
      <div v-if="triggerindex == 3">
        <div v-if="distinguish">
          <div
            v-if="
              this.$store.state.userType != 'user_type_02' ||
              this.$store.state.token == ''
            "
          >
            <div class="contant-text">
              <p class="chose-title">团体用户未登录</p>
              <p class="type-name">
                请首先 <a @click="applyfor(1)">[登录团体用户]</a>，或首先
                <a href="/groupRegistration">[注册团体用户]</a>。
              </p>
            </div>
          </div>
          <div
            v-else-if="
              this.$store.state.userType == 'user_type_02' &&
              isCurrentTimeGreaterThanTarget(basicdate.registerDateEnd)
            "
          >
            <div class="contant-text" style="margin-top: 30px">
              <!-- <p class="chose-title">团体用户未登录</p> -->
              <p class="type-name">该赛事报名已经结束</p>
            </div>
          </div>
          <div
            v-else-if="
              this.$store.state.userType == 'user_type_02' &&
              !isCurrentTimeGreaterThanTarget(basicdate.registerDateStart)
            "
          >
            <div class="contant-text" style="margin-top: 30px">
              <!-- <p class="chose-title">团体用户未登录</p> -->
              <p class="type-name">
                报名未开始，报名时间是{{ basicdate.registerDateStart }}，
                <a @click="$router.back(0)">[返回报名首页]</a>。
              </p>
            </div>
          </div>
          <div v-else>
            <div class="contant-text" style="margin-top: 30px">
              <!-- <p class="chose-title">团体用户未登录</p> -->
              <p class="type-name">
                <a @click="determine" target="_blank"
                  >请您进入团体用户中心，完成本赛事的报名操作</a
                >
              </p>
            </div>
          </div>
        </div>
        <div v-else>
          <div
            v-if="
              this.$store.state.userType != 'user_type_01' ||
              this.$store.state.token == ''
            "
          >
            <div class="contant-text">
              <p class="chose-title">个人用户未登录</p>
              <p class="type-name">
                请首先 <a @click="applyfor(2)">[登录个人用户]</a>，或首先
                <a href="/groupRegistration">[注册个人用户]</a>。
              </p>
            </div>
          </div>
          <div
            v-else-if="
              this.$store.state.userType == 'user_type_01' &&
              isCurrentTimeGreaterThanTarget(basicdate.registerDateEnd)
            "
          >
            <div class="contant-text" style="margin-top: 30px">
              <p class="type-name">该赛事报名已经结束</p>
            </div>
          </div>
          <div
            v-else-if="
              this.$store.state.userType == 'user_type_01' &&
              !isCurrentTimeGreaterThanTarget(basicdate.registerDateStart)
            "
          >
            <div class="contant-text" style="margin-top: 30px">
              <p class="type-name">
                报名未开始，报名时间是{{ basicdate.registerDateStart }}，
                <a @click="$router.back(0)">[返回报名首页]</a>。
              </p>
            </div>
          </div>
          <div v-else>
            <div class="contant-text contant-box">
              <p class="type-name">个人报名请点击</p>

              <div class="grbut" @click="signup">
                <img src="../assets/image/user3.png" alt="" />
                <p>个人报名</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import Meta from 'vue-meta'
Vue.use(Meta)
export default {
  name: '',
  components: {},
  data() {
    return {
      id: '',
      date: '',
      triggerindex: 1,
      title: '',
      title1: '',
      title2: '',
      title3: '',
      title4: '',
      imgs: '',
      total: 0,
      columnId: '',
      columnPid: '',
      tableData: [],
      options1: [],
      groupName: '',
      basicdate: {},
      eventdate: {},
      image: '',
      titlename: '',
      domain: 'https://t-jsglzx.bgosp.com',
      domain2: 'https://admin.bjcac.org.cn',
      distinguish: false
    }
  },
  created() {
    this.id = this.$route.query.id
    this.columnId = this.$route.query.columnId
    this.columnPid = this.$route.query.columnPid
    this.getBasicEnrollInfoByMatchId()
    this.getMatchInfoByMatchId()
    this.eventIntroduction()
    this.findAllColumn()
  },
  computed() {

  },
  methods: {
    findAllColumn() {
      this.$api.findAllColumn({
        id: this.columnId,
        pid: this.columnPid
      }).then(res => {
        this.titlename = res.data.data.pid.name
      })
    },
    getBasicEnrollInfoByMatchId() {
      this.$api.getBasicEnrollInfoByMatchId(this.$route.query.id).then(res => {
        this.basicdate = res.data.data.result
        if (res.data.data.result.registerMethod == 1) {
          this.distinguish = false
        } else {
          this.distinguish = true
        }
      })
    },
    eventIntroduction() {
      this.$api.eventIntroduction(this.$route.query.id).then(res => {
        res.data.data.result.matchAddressStadium = res.data.data.result.addresses.map(item => {
          return item.matchAddressStadium
        }).toString()
        if (res.data.data.result.matchImgs && res.data.data.result.matchImgs.length > 0) {
          this.$api.getFile({
            objectName: res.data.data.result.matchImgs[0].filePath
          }).then(res => {
            this.$convert(res.data.data.result.url).then((fileUrl) => {
              this.image = fileUrl
              this.$forceUpdate()
            })
          })
        }

        if (res.data.data.result.matchRules) {
          res.data.data.result.matchRules = this.updatedHtml(res.data.data.result.matchRules)
          let imgReg = RegExp(/<img [^>]*src=['"]([^'"]+)[^>]*>/gi); //定义正则，筛选出img元素
          let matchres = res.data.data.result.matchRules.match(imgReg);
          if (matchres) {
            matchres.forEach((item) => {
              if (item.indexOf('staticMinioFileCheckSecurity') == -1) {
                return // 说明是历史数据需要返回原来的 不需要改变
              }
              // 从富文本中拿到img标签 并去掉 "/>"
              let _tempStr = item.slice(0, item.length - 2);
              let _index = _tempStr.indexOf('?');
              // 拿到文件的所有旧文件标签 [ img, img, img]
              const srcRegExp = /(\/|http)[^>]+\.(jpg|jpeg|png|gif)/g;
              const _InitialImgSrc = _tempStr.match(srcRegExp);
              // 拿到 minFileName 获取新的src
              if (_InitialImgSrc && _InitialImgSrc.length > 0) {
                // 拿到文件的minFileName  事例： jeditor/90490405b7384040bfdd1d6e7bc14a79_1688977979650OSS.png
                let _fileNameIndex = _InitialImgSrc[0].indexOf('jeditor/');
                let _minFileName = _InitialImgSrc[0].substring(_fileNameIndex,
                  _InitialImgSrc[0].length);
                // 拿到文件的minFileName + 后续属性 事例： jeditor/31580d55a8cc489c8d8aa92f59b310c0_1688977994678OSS.png" alt="" width="48" height="48"
                let longHtml = _tempStr.substring(_tempStr.indexOf(_minFileName),
                  _tempStr
                    .length);
                // 拿到属性 事例： alt="" width="100" height="100"
                let propName = longHtml.substring(longHtml.indexOf(' '), longHtml
                  .length);
                // 获取新的验签
                const params = this.$getSign(_minFileName);
                // 拼接img标签
                let _str = _tempStr.substring(0, _index) + '?' + params + '"' +
                  propName +
                  '/>';
                // 替换img标签
                res.data.data.result.matchRules = res.data.data.result
                  .matchRules.replace(item, _str);
              }
            });
          }

          this.date = res.data.data.result.matchRules
        }
        res.data.data.result.matchDate = JSON.parse(res.data.data.result.matchDate)
        this.eventdate = res.data.data.result

        document.title = res.data.data.result.matchName + '-北京市体育竞赛管理和国际交流中心'
        this.$meta().refresh()
      })
    },
    getMatchInfoByMatchId() {
      let data = {
        matchId: this.id,
      }
      this.$api.getMatchInfoByMatchId(data).then(res => {
        let options1 = []
        let tableData = []
        for (let i in res.data.data.result.matchSettings) {
          res.data.data.result.matchSettings[i].projects.forEach(item => {
            item.groupName = res.data.data.result.matchSettings[i].groupName
            item.birthDate = res.data.data.result.matchSettings[i].birthDate
            item.sex = res.data.data.result.matchSettings[i].sex == 1 ? '男' : (res.data
              .data.result.matchSettings[i].sex == 2 ? '女' : '混合')
          })
          options1.push(res.data.data.result.matchSettings[i])
          tableData.push(res.data.data.result.matchSettings[i].projects)
        }
        this.options1 = options1
        this.tableData = tableData.flat()
      })
    },
    updatedHtml(url) {
      const imgSrcRegex = /<img[^>]+src="([^"]+)"/g;
      let updatedHtml = url;
      let match;
      while ((match = imgSrcRegex.exec(url)) !== null) {
        const imgSrc = match[1];
        if (!/^https?:\/\//i.test(imgSrc)) {
          if (process.env.VUE_APP_BASE_API == 'https://www.bjcac.org.cn') {
            const updatedSrc = `${this.domain2.replace(/\/$/, '')}/${this.removeDotDotSlash(imgSrc)}`;
            updatedHtml = updatedHtml.replace(imgSrc, updatedSrc);
          } else {
            const updatedSrc = `${this.domain.replace(/\/$/, '')}/${this.removeDotDotSlash(imgSrc)}`;
            updatedHtml = updatedHtml.replace(imgSrc, updatedSrc);
          }
        }
      }
      return updatedHtml;
    },
    removeDotDotSlash(url) {
      let updatedUrl = url;
      while (updatedUrl.indexOf('../') !== -1) {
        updatedUrl = updatedUrl.replace('../', '');
      }
      return updatedUrl;
    },
    gettrigger(e) {
      this.triggerindex = e
      if (e == 1 || e == 2) {
        this.getMatchInfoByMatchId()
      }
      this.groupName = ''
    },
    gradeGrouping() {
      for (let i in this.options1) {
        if (this.options1[i].groupName == this.groupName) {
          this.tableData = this.options1[i].projects
        }
      }
    },
    determine() {
      this.$api.getTimeStamp().then(ess => {
        let data = {
          accessToken: this.$store.state.token,
          timestampe: ess.data.data.timestamp,
          redirectURL: '/match/enrollApply?matchName=' + this.eventdate.matchName
        }
        this.$api.getCode(data).then(err => {
          let url = process.env.VUE_APP_BASE_API_ADMIN + '/tokenLogin?code=' + err.data.data
            .result.code
          window.open(url, '_self');
        })
      })
    },
    applyfor(e) {
      this.$store.commit("EXIT_TOKEN")
      if (e == 1) {
        this.$router.push({
          path: '/login',
          query: {
            person: false,
            grp: true,
            refresh: new Date().getTime()
          }
        })
      } else {
        this.$router.push({
          path: '/login',
          query: {
            person: true,
            grp: false,
            refresh: new Date().getTime()
          }
        })
      }
    },
    isCurrentTimeGreaterThanTarget(targetTime) {
      // 获取当前时间的时间戳（单位：毫秒）
      const currentTimeStamp = new Date().getTime();
      // 使用正则表达式来解析日期时间字符串
      const match = /^(\d{4})-(\d{2})-(\d{2}) (\d{2}):(\d{2}):(\d{2})$/.exec(targetTime);
      if (!match) {
        // 如果日期时间字符串不匹配预期的格式，返回错误或者做适当处理
        return false;
      }
      // 提取年、月、日、小时、分钟和秒
      const [, year, month, day, hour, minute, second] = match;
      // 构建目标时间的Date对象
      const targetDate = new Date(year, month - 1, day, hour, minute, second);
      // 获取目标时间的时间戳
      console.log(targetDate)
      const targetTimeStamp = targetDate.getTime();
      // 比较当前时间和目标时间
      if (currentTimeStamp > targetTimeStamp) {
        return true;
      } else if (currentTimeStamp < targetTimeStamp) {
        return false;
      }
    },
    signup() {
      if (this.$store.state.token == '') {
        this.$confirm('当前尚未登录，请登录', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.$store.commit("EXIT_TOKEN")
          this.$router.push({
            path: '/login',
            query: {
              person: true,
              grp: false,
              refresh: new Date().getTime()
            }
          })
        }).catch(() => { });
        return
      }
      if (this.$store.state.userType == 'user_type_02') {
        this.$confirm('当前账号为团体账号，是否切换成个人账号', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.$store.commit("EXIT_TOKEN")
          this.$router.push({
            path: '/login',
            query: {
              person: true,
              grp: false,
              refresh: new Date().getTime()
            }
          })
        }).catch(() => { });
        return
      }

      if (this.$store.state.realnameornot.caStatus == 0) { // 未人脸识别
        if (!this.$store.state.realnameornot.createTime) {
          this.$confirm('当前账号尚未身份验证，是否前往身份验证', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(() => {
            this.$router.push({
              path: '/registerIndex',
              query: {
                refresh: new Date().getTime(),
                menuIndex: 4,
              },
            })
          }).catch(() => { });
          return
        }
        if (this.$store.state.realnameornot.createTime && this.$store.state.realnameornot.requeststatus == 0) {
          this.$alert('当前账号身份验证审核中，请等待审核完成', '提示', {
            confirmButtonText: '确定',
            type: 'warning',
            callback: action => {
              this.$router.push({
                path: '/registerIndex',
                query: {
                  refresh: new Date().getTime(),
                  menuIndex: 4,
                  nameornot: true
                },
              })
            }
          });
          return
        }
        if (this.$store.state.realnameornot.createTime && this.$store.state.realnameornot.requeststatus == 2) {
          this.$alert('当前账号身份验证未通过，请先完成身份验证', '提示', {
            confirmButtonText: '确定',
            type: 'warning',
            callback: action => {
              this.$router.push({
                path: '/registerIndex',
                query: {
                  refresh: new Date().getTime(),
                  menuIndex: 4,
                  nameornot: true
                },
              })
            }
          });
          return
        }
        if (this.$store.state.realnameornot.createTime && this.$store.state.realnameornot.requeststatus == 1) {
          this.$router.push({
            path: '/individualregistration',
            query: {
              title: this.eventdate.matchName,
              matchId: this.$route.query.id,
              refresh: new Date().getTime()
            }
          })
          return
        }
      } else { // 通过人脸识别
        if (this.$store.state.realnameornot.caStatus == 2) {
          this.$alert('当前账号人脸识别未通过，请重新认证', '提示', {
            confirmButtonText: '确定',
            type: 'warning',
            callback: action => {
              this.$router.push({
                path: '/registerIndex',
                query: {
                  refresh: new Date().getTime(),
                  menuIndex: 4,
                  nameornot: true
                },
              })
            }
          });
        } else if (this.$store.state.realnameornot.requeststatus == 0 && this.$verification(this.$store.state.realnameornot.headPic)) {
          this.$alert('当前账号未上传免冠照，请上传', '提示', {
            confirmButtonText: '确定',
            type: 'warning',
            callback: action => {
              this.$router.push({
                path: '/registerIndex',
                query: {
                  refresh: new Date().getTime(),
                  menuIndex: 7,
                  photoau: false
                },
              })
            }
          });
        } else if (this.$store.state.realnameornot.requeststatus == 0 && !this.$verification(this.$store.state.realnameornot.headPic)) {
          this.$alert('当前账号免冠照审核中，请等待审核完成', '提示', {
            confirmButtonText: '确定',
            type: 'warning',
            callback: action => {
              this.$router.push({
                path: '/registerIndex',
                query: {
                  refresh: new Date().getTime(),
                  menuIndex: 7,
                  photoau: true
                },
              })
            }
          });
        } else if (this.$store.state.realnameornot.requeststatus == 2) {
          this.$alert('当前账号免冠照审核未通过，请重新上传', '提示', {
            confirmButtonText: '确定',
            type: 'warning',
            callback: action => {
              this.$router.push({
                path: '/registerIndex',
                query: {
                  refresh: new Date().getTime(),
                  menuIndex: 7,
                  photoau: true
                },
              })
            }
          });
        } else if (this.$store.state.realnameornot.caStatus == 1 && this.$store.state.realnameornot.requeststatus == 1) {
          this.$router.push({
            path: '/individualregistration',
            query: {
              title: this.eventdate.matchName,
              matchId: this.$route.query.id,
              refresh: new Date().getTime()
            }
          })
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.header {
	display: flex;
	width: 1200PX;
	margin: 34PX auto 0;
	font-size: 18PX;
	font-weight: 500;
	color: #333333;
	cursor: pointer;
}

.current {
	color: #0066FF;
}

.active {
	background: #0063FF !important;
	color: #FFFFFF !important;
}

.head {
	width: 1200PX;
	margin: 0 auto;
	height: 517PX;
	background: #F8FBFF;
	border-radius: 22PX;
	margin-top: 39PX;
	display: flex;
	flex-direction: column;
}

.head-img {
	width: 587PX;
	height: 387PX;
	background: #FFFFFF;
	box-shadow: 0PX 6PX 14PX 2PX rgba(0, 99, 255, 0.1);
	border-radius: 19PX;
	margin-top: 14PX;
	margin-left: 53PX;
	min-width: 587PX;
}

.head-tetx {
	margin-left: 49PX;
	margin-top: 13PX;
	margin-right: 10PX;
}

.head-title {
	font-size: 25PX;
	font-weight: 400;
	color: #000000;
	margin-bottom: 20PX;
	width: 1200PX;
	text-align: center;
	margin-top: 20PX;
	padding: 0 10PX;
}

.head-name {
	width: 500PX;
	font-size: 17PX;
	font-weight: 400;
	color: #000000;
	margin-bottom: 8PX;

}

.nav-box {
	display: flex;
	width: 1200PX;
	margin: 38PX auto 0;
}

.nav {
	width: 155PX;
	height: 49PX;
	background: #ECF1FD;
	border-radius: 12PX 12PX 0PX 0PX;
	font-size: 20PX;
	font-weight: bold;
	color: #0063FF;
	margin-right: 7PX;
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
}

.line {
	width: 1200PX;
	margin: 0 auto 33PX;
	height: 2PX;
	background: #0063FF;
	border-radius: 1PX;
}

.display-box {
	width: 1200PX;
	margin: 0 auto 60PX;
	min-height: 200PX;
	background: #F8FBFF;
	border-radius: 22PX;
	padding: 35PX;
}

.searchfor {
	height: 123PX;
	background: rgba(216, 233, 255, 0.38);
	border-radius: 7PX;
	margin-top: 15PX;
	display: flex;
	padding-left: 30PX;
}

.searchfor ::v-deep .el-input__inner {
	width: 250PX;
	height: 43PX;
	background: #FFFFFF;
	border: 1PX solid #003680;
	border-radius: 7PX;
	font-size: 18PX;
	color: #333333;
	padding: 0 15PX 0 120PX;
}

.searchfor ::v-deep input::-webkit-input-placeholder {
	color: #333333;
}

.searchfor ::v-deep input::-moz-input-placeholder {
	color: #333333;
}

.searchfor ::v-deep input::-ms-input-placeholder {
	color: #333333;
}

.searchfor ::v-deep .el-select .el-input .el-select__caret {
	color: #191919;
}

.searchfor .searchforlogo {
	display: block;
	width: 28PX;
	height: 27PX;
	margin-top: 8PX;
	margin-left: 17PX;
}

.searchfor .searchforlogo img {
	width: 100%;
	height: 100%;
}

.searchfor .title {
	font-size: 18PX;
	font-weight: bold;
	color: #333333;
	margin-top: 9PX;
	margin-left: 13PX;
}

.searchfor .inp ::v-deep .el-input__inner {
	width: 201PX;
	height: 43PX;
}

.project {
	margin-top: 41PX;
}

.level {
	margin-top: 41PX;
	margin-left: 66PX;
}

.area {
	margin-top: 41PX;
	margin-left: 66PX;
}

.but {
	width: 150PX;
	height: 43PX;
	background: #164B92;
	border-radius: 9PX;
	display: flex;
	align-items: center;
	margin-top: 41PX;
	margin-left: 29PX;
	cursor: pointer;
}

.but .img {
	width: 21PX;
	height: 21PX;
	margin-left: 31PX;
}

.but .text {
	font-size: 19PX;
	font-weight: bold;
	color: #FFFFFF;
	margin-left: 7PX;
	letter-spacing: 4PX;
}

.table {
	margin-top: 40PX;
	margin-bottom: 80PX;
}

.table ::v-deep .el-table thead {
	color: #fff;
	font-size: 16PX;
	font-weight: 500;
}

.table ::v-deep .el-table__header-wrapper {
	border-top-left-radius: 10PX;
	border-top-right-radius: 10PX;
}

.table ::v-deep .el-table {
	border-top-left-radius: 10PX;
	border-top-right-radius: 10PX;
}

.table ::v-deep .el-table th.el-table__cell.is-leaf {
	border-bottom: 1PX solid #000;
	border-right: 1PX solid #000;
}

.table ::v-deep .el-table td,
.el-table th.is-leaf,
.el-table--border,
.el-table--group {
	border-color: black;
}

.table ::v-deep .el-table--border::after,
.el-table--group::after,
.el-table::before {
	background-color: black;
}

.el-select-dropdown__item {
	font-size: 18PX;
}

.paging {
	margin-top: 18PX;
	display: flex;
	align-items: center;
}

.paging span {
	margin-right: 5PX;
}

.contant-text {
	padding: 20PX;
}

.contant-text .chose-title {
	text-align: center;
	font-size: 24PX;
	line-height: 3em;
	color: #666;
}

.contant-text .type-name {
	text-align: center;
	font-size: 18PX;
	line-height: 2em;
	color: #333;
	cursor: pointer;
}

.titlename:hover {
	color: #0066FF;
}

.ie-browser .table ::v-deep .el-table__header {
	width: 100% !important;
}

.ie-browser .table ::v-deep .el-table__body {
	width: 100% !important;
}

.grbut {
	width: 380PX;
	height: 60PX;
	background: linear-gradient(90deg, #6FB2FF, #095FFF);
	margin-top: 10PX;
	cursor: pointer;
	color: #fff;
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 20PX;

	img {
		height: 20PX;
		width: 26PX;
		margin-right: 5PX;
	}
}

.contant-box {
	display: flex;
	flex-direction: column;
	align-items: center;
}
</style>